import React, { FC, ReactNode, useState, useEffect } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout, Elements, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}
const stripePromise = loadStripe("pk_live_51HPwTfA1vlKeFIu1ZBrCDuJUSuqOywzBgdztabnLXN2lwfgstUnI4xwEmfEwRJtocrIwB8tqqdDlNqg4mN3FCkjc00XLXd9MF9");
const CheckoutForm = ({ amount, clientSecret }: { amount: string; clientSecret: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const history = useHistory();
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    /*try {
         const result = await stripe.confirmPayment({
           elements,
           confirmParams: {
             // Rimuovi return_url per gestire manualmente il redirect
           },
           // Opzionale: Imposta il tipo di redirect
           redirect: "if_required", // Può essere 'always' o 'never' a seconda del tuo flusso
         });

         if (result.error) {
           // Gestisci gli errori restituiti da Stripe
           setError(result.error.message || "Si è verificato un errore");
         } else {
           const { paymentIntent } = result;
           if (paymentIntent?.status === "succeeded") {
             // Naviga alla pagina di completamento con l'ID del pagamento
             history.push(`/completion?id=${paymentIntent.id}`);
           } else if (paymentIntent?.status === "requires_action") {
             // Gestisci eventuali azioni aggiuntive (es. autenticazione 3D Secure)
             // Puoi richiamare nuovamente stripe.confirmPayment se necessario
           }
         }
       } catch (error) {
         setError("Si è verificato un errore durante il pagamento");
       } finally {
         setProcessing(false);
       }*/

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/completion`,
        },
      });

      if (result.error) {
        // Handle any errors returned by Stripe
        setError(result.error.message || "Si è verificato un errore");
      }
    } catch (error) {
      setError("Si è verificato un errore durante il pagamento");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <PaymentElement />
      {error && <div className="text-red-500 mt-2">{error}</div>}
      {/* <button type="submit" disabled={!stripe || processing} className="mt-4 bg-primary-600 text-white px-4 py-2 rounded-lg disabled:opacity-50">
        {processing ? "Elaborazione..." : `Paga ${amount}€`}
      </button> */}
      <ButtonPrimary
        type="submit"
        disabled={!stripe || processing}
        className="mt-4 bg-primary-600 text-white px-4 py-2 rounded-lg disabled:opacity-50 w-full"
      >
        {processing ? "Elaborazione..." : `Paga ${amount}€`}
      </ButtonPrimary>
    </form>
  );
};

const SectionHero: FC<SectionHeroProps> = ({ className = "", rightImg, heading, subHeading, btnText }) => {
  const [acquistato, setAcquistato] = useState(0);
  const [showDiv, setShowDiv] = useState(false);
  const [speso, setSpeso] = useState(0);
   const [card, setCard] = useState<any | null>(null);
  const [codice, setCodice] = useState("");
  const [conto, setConto] = useState("");
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [saldo, setSaldo] = useState(0);
  const [orders, setOrders] = useState([]);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const getBracciale = () => {
    fetch(`https://booking.jooba.it/saldo_card?codice=${codice}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setCard(response.card)
        setAcquistato(response.acquistato);
        setSpeso(response.venduto);
        setSaldo(response.saldo);
        setConto(response.conto);
        setOrders(response.orders || []);
      })
      .catch((error) => alert("Nessuna card trovata"));
  };

  const handlePayment = async () => {

    if(!showDiv) {
      setShowDiv(true)
      return;
    }
    // Handle payment logic here
    if (!codice || codice == "") {
      alert("Non hai inserito nessun codice");
      return;
    }

    if (!nome || nome == "") {
      alert("Inserire il campo nome");
      return;
    }

    if (!cognome || cognome == "") {
      alert("Inserire il campo cognome");
      return;
    }
    if (!email || email == "") {
      alert("Inserire il campo email");
      return;
    }

 
    const formData = {
      name: nome,
      surname: cognome,
      email: email,
      codice:card?.barcode || '',
      simple_code:card?.code || '',
      price: Math.abs(saldo),
      conto:conto
    };

    try {
      const res = await fetch("https://dev.nubble.it/stripe_umoya", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await res.json();
      if (data && data.clientSecret) {
        setClientSecret(data.clientSecret);
      } else {
        throw new Error("Client secret not found in the response");
      }
    } catch (error) {
      console.error("fetchClientSecret failed", error);
    }
  };

  return (
    <div className={`nc-SectionHero relative ${className}`} data-nc-id="SectionHero">
      {/* Balance Display */}
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-neutral-900 dark:text-neutral-100">💰 Totale: {saldo} €</h1>
      </div>

      <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">{heading}</h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">{subHeading}</span>
          <div className="relative max-w-sm">
            <Input
              required
              value={codice}
              aria-required
              onInput={(e) => setCodice((e.target as HTMLInputElement).value)}
              placeholder="Codice bracciale"
              type="text"
            />
            <ButtonCircle
              type="submit"
              onClick={() => {
                getBracciale();
              }}
              className="absolute transform top-1/2 -translate-y-1/2 right-1"
            >
              <i className="las la-arrow-right text-xl"></i>
            </ButtonCircle>
          </div>
        </div>

        {showDiv && (
          <div className="flex-grow w-full">
            <div className="mt-6 max-w-full mx-auto">
              <Input
                required
                value={nome}
                aria-required
                onInput={(e) => setNome((e.target as HTMLInputElement).value)}
                placeholder="Nome"
                type="text"
              />
              <br></br>
              <Input
                required
                value={cognome}
                aria-required
                onInput={(e) => setCognome((e.target as HTMLInputElement).value)}
                placeholder="Cognome"
                type="text"
              />
              <br></br>
              <Input
                required
                value={email}
                aria-required
                onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
                placeholder="Email"
                type="text"
              />
              {clientSecret && (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                    appearance: {
                      theme: "stripe",
                    },
                  }}
                >
                  <CheckoutForm amount={Math.abs(saldo).toFixed(2)} clientSecret={clientSecret} />
                </Elements>
              )}
            </div>
            {!clientSecret && (
              <div className="mt-6 text-center">
                <ButtonPrimary onClick={handlePayment}>Convalida dati</ButtonPrimary>
              </div>
            )}
          </div>
        )}

        {/* <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div> */}
      </div>

      {/* Orders Table */}
      {orders.length > 0 && (
        <div className="mt-10 overflow-x-auto">
          <table className="min-w-full bg-white dark:bg-neutral-800">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-neutral-200 dark:border-neutral-700 text-left leading-4 text-neutral-900 dark:text-neutral-200 tracking-wider">
                  ID
                </th>
                <th className="px-6 py-3 border-b-2 border-neutral-200 dark:border-neutral-700 text-left leading-4 text-neutral-900 dark:text-neutral-200 tracking-wider">
                  Prodotto
                </th>
                <th className="px-6 py-3 border-b-2 border-neutral-200 dark:border-neutral-700 text-left leading-4 text-neutral-900 dark:text-neutral-200 tracking-wider">
                  Prezzo
                </th>
                <th className="px-6 py-3 border-b-2 border-neutral-200 dark:border-neutral-700 text-left leading-4 text-neutral-900 dark:text-neutral-200 tracking-wider">
                  Quantità
                </th>
                <th className="px-6 py-3 border-b-2 border-neutral-200 dark:border-neutral-700 text-left leading-4 text-neutral-900 dark:text-neutral-200 tracking-wider">
                  Data
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order: any) => (
                <tr key={order.id}>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-neutral-200 dark:border-neutral-700">{order.id}</td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-neutral-200 dark:border-neutral-700">{order.prodotto}</td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-neutral-200 dark:border-neutral-700">{order.prezzo} €</td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-neutral-200 dark:border-neutral-700">{order.qta}</td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-neutral-200 dark:border-neutral-700">{order.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Payment Button */}
          {!clientSecret && (
            <div className="mt-6 text-center">
              <ButtonPrimary onClick={handlePayment}>Vai al pagamento</ButtonPrimary>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionHero;
