import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { useLocation } from "react-router-dom";
import {QRCode} from "react-qrcode";

export interface CompleteProps {
  className?: string;
}

const Complete: FC<CompleteProps> = ({ className = "" }) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const [paymentId, setPaymentId] = React.useState<string | null>(null);

  React.useEffect(() => {
    const id = query.get("id");
    setPaymentId(id);
  }, [query]);

  
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout">
      <Helmet>
        <title>Umoya - Wallet</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-neutral-900 dark:text-neutral-100">PAGAMENTO EFFETTUATO CON SUCCESSO</h1>
        
          <div className="bg-white shadow-lg p-8 rounded-lg">
            <h2 className="text-lg font-medium mb-4">Presenta la card all'uscita per il checkout</h2>
{/* 
            <div className="flex justify-center">
              <QRCode value={paymentId} />
            </div> */}
            {/* <p className="mt-4">Codice pagamento: {paymentId}</p> */}
          </div>
     
      </div>
    </div>
  );
};

export default Complete;
