import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import Logonero from "shared/Logo/Logonero";
import Button, { ButtonProps } from "shared/Button/Button";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Umoya",
    menus: [
      { href: "#", label: "Chi siamo" },
      { href: "#", label: "Contattaci" },
      { href: "#", label: "Come trovarci" },
      { href: "#", label: "Eventi" }
    ],
  },
];

const Footer: React.FC = () => {

  return (
    <div className="nc-Footer relative py-20 lg:py-32 border-t border-neutral-200 dark:border-neutral-700 bg-primary-100">
      <div className="container grid lg:grid-cols-3 sm:grid-cols-1 justify-items-center">


        <div className="grid grid-cols-1 place-items-center mb-10 lg:mt-20">
            <SocialsList1 className="flex space-x-3 mt-4"/>
            <a href={'tel:0823316590'}><i className={"las la-phone"}></i> +39 0823 316590</a>
            <a href={'mailto:info@umoya.it'}><i className={"las la-at"}></i> info@umoya.it</a>

            <button className={`bg-primary-6000 hover:bg-primary-700 text-neutral-50 mt-4 p-4`} >
            <a href="https://www.google.com/maps/dir//Umoya+-+Natural+Experience+Via+Mezzagni+81030+Castel+Volturno+CE/@41.0050768,13.9781923,10z/data=!4m5!4m4!1m0!1m2!1m1!1s0x133b1f16c045d239:0xe5a93cdd704208c3" target="_blank">Avvia Navigatore</a>
            </button>
        </div>


        <div className="grid grid-cols-1 place-items-center text-center">
            <Logonero />
            Via Mezzagni SNC - Ecoparco del Mediterraneo <br />
            81030 Castel Volturno (CE) <br />
            C.F. 08616211218
            <span className="text-sm"><a href="https://www.umoya.it/cookie-policy/" target="_blank" className="underline">Cookie Policy</a><a href="https://www.umoya.it/privacy-policy/" className="underline ml-6" target="_blank">Privacy Policy</a></span>
        </div>


        <div className="grid grid-cols-1 place-items-center text-center mt-10 lg:mt-10">
          <span className="text-sm">Orari di apertura  – <a href="https://www.umoya.it/prenotazioni/" className="underline">More Info</a></span>
          <span className="text-sm lg:-mt-6"> Prenotazione obbligatoria – <a href="https://www.umoya.it/contatti/" className="underline">Come prenotare</a></span>
          <span className="text-sm lg:-mt-6">Diritto di ammissione</span>

          <button className={`bg-primary-6000 hover:bg-primary-700 text-neutral-50 p-4`} >
            <a href="https://www.umoya.it/wp-content/uploads/2021/08/MAPPA-UMOYA-2021.pdf" target="_blank">Scarica la piantina</a>
          </button>
        </div>


      </div>
    </div>
  );
};

export default Footer;
